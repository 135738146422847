import React, { useEffect, useRef } from "react";
import _isEqual from "lodash-es/isEqual";

import { PageError, PageLoading } from "../components/shared/message/Message";
import stateTypes from "./constants/stateTypes";
import getAxios from "./axios";
import queryString from "query-string";
import EsV2QueryParams from "./constants/EsV2QueryParams";
import moment from "moment";
import { PASSWORD_REGEX, REDUX_BASED_ACTION_TYPES, COOKIE_KEYS, COOKIE_TTL_FOR_WEB, WEB_GLOBAL_API_VERSION, CLIENT_TYPE_PETPOOJA, CHECKOUT_MODE, APP_MODE, APP_TYPE, MOBILE_WEB_SCREEN_SIZE, LOCAL_STORE_KEYS } from "./constants/others";
import { APP_MODE_STANDARD, HP_PLUS_PLAN_CARD } from "./constants/hpPlusConstants";
function loadingHelper(statuses, render, conditions, dontShowError) {
    // check pre conditions
    if (conditions && conditions.pre) {
        for (const condition of conditions.pre) {
            const bool =
                typeof condition.check === "function" ?
                    condition.check() :
                    condition.check;

            if (!bool)
                return typeof condition.render === "function" ? (
                    condition.render(condition.error)
                ) : (<
                    PageError error={condition.error}
                />
                );
        }
    }

    // check statuses
    let count = 0;
    for (const status of statuses) {
        if (status.state === stateTypes.STATE_FAIL) {
            if (!dontShowError) {
                return typeof status.render === "function" ? (
                    status.render(status.error)
                ) : (<
                    PageError error={status.error}
                />
                );
            } else {
                return (<PageLoading />)
            }
        } else if (status.state === stateTypes.STATE_DONE) {
            count++;
        } else {
            if (status.check === false) {
                count++;
            }
        }
    }

    if (statuses.length !== count) {
        return (<PageLoading />);
    }

    // check post conditions
    if (conditions && conditions.post) {
        for (const condition of conditions.post) {
            const bool =
                typeof condition.check === "function" ?
                    condition.check() :
                    condition.check;

            if (!bool)
                return typeof condition.render === "function" ? (
                    condition.render(condition.error)
                ) : (<
                    PageError error={condition.error}
                />
                );
        }
    }

    return render();
}

/**
 * Removes 00's from decimal if present, and prepends rupee symbol
 */
function getFormattedINRValues(inr, defaultVal = null) {
    return inr ?
        `₹${inr.toFixed(2).split(".")[1] === "00" ? inr.toFixed(0) : inr.toFixed(2)
        }` :
        defaultVal;
}

function isTabSelected(title) {
    if (typeof window !== "undefined") {
        const currentPagePathname = window.location.pathname
        const bottomBarLinkMapping = {
            Shop: "/",
            "My list": "/buyer/entity/MY_LIST",
            Wallet: "/buyer/credit",
            Orders: "/buyer/order",
            "Pay Later": "/paylater",
            "Credit": "/buyer/credit-limit"
        };
        return bottomBarLinkMapping[title] === "/"
            ? currentPagePathname === "/"
            : currentPagePathname.includes(bottomBarLinkMapping[title]);
    }
}

function parseJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        //JSON is not okay
        return {};
    }

    return JSON.parse(str);
}


function getThousandsFormatCurrency(inr = 0) {
    if (parseFloat(inr)) {
        const value = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
        }).format(inr);

        return value.includes(".00") ? value.split(".00")[0] : value;
    } else {
        return "₹" + inr;
    }
}

function debounce(fn, delay = 1000) {
    let timerId;
    return function (val, clearDebounce = false) {
        let args = [...arguments];
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn.apply(this, args);
            timerId = null;
        }, delay);

        if (clearDebounce) {
            clearTimeout(timerId);
        }
    };
}

function getCache(ref, args, fn) {
    let value;

    if (!ref.current) {
        value = fn(...args);
        ref.current = {
            args: [...args],
            value: value,
        };
    } else {
        if (
            ref.current.args.length === 0 ||
            args.length === 0 ||
            !_isEqual(ref.current.args, args)
        ) {
            value = fn(...args);
            ref.current.args = [...args];
            ref.current.value = value;
        } else {
            value = ref.current.value;
        }
    }

    return value;
}

// return top scroll value
function getScroll() {
    let pageYOffset;
    if (pageYOffset !== undefined) {
        return [pageYOffset];
    } else {
        let sy,
            d = document,
            r = d.documentElement,
            b = d.body;
        sy = r.scrollTop || b.scrollTop || 0;
        return sy;
    }
}

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

function useOnUpdate(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current)
            return fn();
        else
            didMountRef.current = true;
    }, inputs);
}

function makeAndSaveBlob(data, fileName, fileExtension) {
    /**
     * Make blob
     */

    let contentType = "";
    switch (fileExtension) {
        case "xlsx":
            contentType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            break;
        case "pdf":
            contentType = "application/pdf";
            break;
        default:
            break;
    }

    const blob = new Blob([data], {
        type: contentType,
    });

    /**
     * Save blob
     */

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        window.navigator.msSaveOrOpenBlob(blob);
    } else {
        // for other browsers
        const url = window.URL.createObjectURL(blob);
        saveLink(url, fileName, fileExtension);
    }
}

function saveLink(url, fileName, fileExtension) {
    // for other browsers
    const link = document.createElement("a");

    // set attributes on link
    link.style.display = "none";
    link.href = url;
    link.download = `${fileName}.${fileExtension}`;

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    if (typeof link.download === "undefined") {
        link.target = "_blank";
    }

    // add to DOM and click
    document.body.appendChild(link);


    link.click();

    // clean up
    link.remove();
    window.URL.revokeObjectURL(url);
}

/**
 * This function removes extra precision from a number
 * @param {number} num number to remove precision from
 * @returns {number} number with removed precision
 */
function removeExtraPrecision(num) {
    // check if num is a whole number
    if (num % 1 === 0) {
        return num;
    } else {
        return parseFloat(num.toFixed(2));
    }
}
function getWindow() {
    if (typeof window !== "undefined") {
        return window
    }
    return undefined
}

function isMobile(mobileWidth) {
    if (getWindow() && window.innerWidth <= (mobileWidth ?? 500)) {
        return true
    }
    return false
}

function parseIntOr0(val) {
    const result = parseInt(val);
    return isNaN(result) ? 0 : result;
}

function isReduxBasedAction(actionType) {
    if (REDUX_BASED_ACTION_TYPES.includes(actionType)) {
        return true
    }
    return false
}

const isRequired = (name) => {
    throw Error(name + ' is required');
};

function handleActionType(
    actionType,
    actionData,
    history,
    otherData = {},
    trackingParams,
    trackParamsCall,
) {
    // otherData keys
    // getCategoryAndSubCategoryNames - CATEGORY, SUBCATEGORY
    // id - PRODUCT
    // commercialName - PRODUCT
    switch (actionType) {
      case "SWITCH_OUTLET":
        history.push("/buyer/outlet");
        break;
      case "WEB_VIEW":
        handleWebViewActonType(
          actionData,
          history,
          trackingParams,
          trackParamsCall
        );
        break;
      case "SEARCH_QUERY":
        handleSearchQueryActionType(actionData, history, otherData);
        break;
      case "CATEGORY":
        handleSwitchCategoryActionType(
          actionData,
          history,
          otherData,
          trackingParams,
          trackParamsCall
        );
        break;
      case "SUBCATEGORY":
        handleSwitchCategoryActionType(
          actionData,
          history,
          otherData,
          trackingParams,
          trackParamsCall
        );
        break;
      case "SWITCH_CATEGORY":
        handleSwitchCategoryActionType(actionData, history, otherData);
        break;
      case "CATALOG":
        handleCatalogActionType(actionData, history, otherData);
        break;
      case "OFFERS":
        handleSwitchCategoryActionType(
          actionData,
          history,
          otherData,
          trackingParams,
          trackParamsCall
        );
        break;
      case "REFERRAL":
        handleReferralActionType(history, otherData);
        break;
      case "CART":
        handleCartActionType(history, otherData);
        break;
      case "HYPERPURE_CREDITS":
        handleHyperpureCreditsActionType(
          history,
          otherData,
          trackingParams,
          trackParamsCall
        );
        break;
      case "CREDIT_NOTE":
        handleCreditNoteActionType(actionData, history, otherData);
        break;
      case "CREDIT_LIMIT":
        handleCreditLimitActionType(
          history,
          otherData,
          trackingParams,
          trackParamsCall
        );
        break;
      case "ORDER_HISTORY":
        handleOrderHistoryActionType(history, otherData);
        break;
      case "ORDER":
        handleOrderActionType(actionData, history, otherData);
        break;
      case "PRODUCT":
        handleProductActionType(actionData, history, otherData);
        break;
      case "PAY_LATER":
        handlePayLaterActionType(history, otherData);
        break;
      case "REWARDS":
        handleRewardsActionType(history);
        break;
      case "PROFILE":
        handleProfileActionType(history);
        break;
      case "HELP":
        handleHelpActionType(history);
        break;
      case "LOGOUT":
        otherData.logout();
        break;
      case "DOWNLOAD_STATEMENT":
        otherData.downloadStatement();
        break;
      case "EMAIL_STATEMENT":
        otherData.emailStatement();
        break;
      case "REQUEST_NEW_PRODUCT":
        window.location.href = `/in/request-product?source=side_menu&searchText=`
        break;
      case "PRODUCT_DETAILS":
        history.push(
          `/buyer/product-detail/${actionData.ProductId}?source=${actionData.Source}&referenceId=${actionData.ReferenceId}&referenceType=${actionData.ReferenceType}`
        );
        break;
      case "CREDIT_PAY":
        handleCreditPayEvent(otherData);
        break;
      case "HOME":
        window.location.href = "/";
        break;
      case "CONSENT":
        handleConsentPopup(trackingParams, trackParamsCall);
        break;
      case "DISMISS_NUDGE":
        handleDismissNudgeCTA(trackingParams, trackParamsCall);
        break;
      case "CREDIT_INTRO":
        handleCreditIntro(history, trackingParams, trackParamsCall, otherData);
        break;
      case "DISPLAY_MESSAGE":
        handleDisplayMessageEvent(otherData);
        break;
      case "INFO_POPUP":
        handleInfoPopup(actionData, otherData, trackingParams, trackParamsCall);
        break;
      case "SWITCH_ENTITY":
        handleSwitchEntity(actionData, otherData);
        break;
      case "UPDATE_PAYMENT_STATUS":
        handleUpdatePaymentStatus(actionData, otherData, history);
        break;
      case "CHATBOT":
        handleChatBot(otherData);
        break;
      case "NOTIFICATION":
        handleNotification(otherData);
        break;
      case "ACCOUNT_STATEMENT":
        handleAccountStatement(otherData);
        break;
      case "ACCOUNT":
        otherData.toggleSidebarHandler();
        break;
      case "MY_LIST":
        window.location.href = "/buyer/entity/MY_LIST/0/1002?&type=MY_LIST";
        break;
      case "SWITCH_APP_MODE":
        otherData.setAppMode(actionData?.Body?.AppMode);
        break
      case "ORDER_RECEIVER_POPUP":
        handleCallBack(otherData.callBack);
        break;
      default:
        break;
    }
}

function handleNotification(otherData) {
    otherData.showNotification()
}

function handleChatBot(otherData) {
    otherData.closeModal()
}
function handleUpdatePaymentStatus(actionData, otherData, history) {
    if (typeof otherData?.updatePaymentStatus === "function") {
        otherData.updatePaymentStatus(actionData?.PaymentGuid, {
            IsPoll: false,
            SourcePage: otherData?.sourcePage
        }, (data) => {
            if (data.TransactionStatus === "pending") {
                history.push("/buyer/payment/result?order_id=" + actionData?.PaymentGuid)
            }
        })
    }
}

function handleCreditIntro(history, trackingParams, trackParamsCall, otherData) {
    if (trackingParams && typeof trackParamsCall == "function") {
        trackParamsCall(trackingParams)
    }
    history.push("/buyer/credit-onboarding")
    if (otherData.trackPopUp) {
        otherData.trackPopUp();
    }
}

function handleInfoPopup(actionData, otherData, trackingParams, trackParamsCall) {
    otherData.createInfoPopup(actionData);
    if (trackingParams && typeof trackParamsCall == "function") {
        trackParamsCall(trackingParams)
    }
}

function handleWebViewActonType(actionData, history, trackingParams, trackParamsCall) {
    if (trackingParams && typeof trackParamsCall == "function") {
        trackParamsCall(trackingParams)
    }
    if (actionData.Endpoint) {
        getAxios(true)
            .get(actionData.Endpoint)
            .then(({ data }) => {
                const url = new URL(data.response.WebViewURL);
                history.push(url.pathname); // TODO, currently not consider query params
            })
            .catch((err) => {
                console.error(err);
            });
    } else if (actionData.WebViewURL) {
        const redirectTo = actionData.WebViewURL;

        if (!redirectTo.includes(".com")) {
            history.push(redirectTo);
        } else {
            // let urlWoHost = redirectTo.split(".com");
            // let urlWoHostPrefix = "/";
            // history.push(redirectTo);
            window.location.assign(redirectTo)
        }
    } else {
        history.push("");
    }
}

function handleCreditPayEvent(otherData) {
    if (otherData.toggleCreditModal) {
        otherData.toggleCreditModal();
    }
    return
}

function handleDisplayMessageEvent(otherData) {
    if (otherData.toggleDAModal) {
        otherData.toggleDAModal();
    }
    return
}

function handleConsentPopup(trackingParams, trackParamsCall) {
    if (typeof trackParamsCall === "function") {
        trackParamsCall(trackingParams);
    }
}

function handleSearchQueryActionType(actionData, history, otherData = {}) {
    const {
        TrackingMetadata
    } = actionData
    const title = encodeURIComponent(actionData.Title);
    let redirectUrl = `/buyer/query/${title}/search?${actionData.SearchQuery}`;
    // if (otherData.sendReference) {
    //     redirectUrl = redirectUrl + `&referenceId=${actionData.ReferenceId}&referenceType=${actionData.ReferenceType}`
    // }
    if (typeof TrackingMetadata === "object") {
        redirectUrl += "&" + queryString.stringify(TrackingMetadata)
    }

    window.location.href = redirectUrl

}

function handleSwitchCategoryActionType(actionData, history, otherData = {}, trackingParams, trackingParamsCall) {
    if (trackingParams && typeof trackingParamsCall == "function") {
        trackingParamsCall(trackingParams)
    }
    const {
        CategoryId,
        SubCategoryId,
        ReferenceId,
        ReferenceType,
        Cheapest,
        Discounted,
    } = actionData

    let redirectUrl = `/buyer/catalog/${CategoryId}/${SubCategoryId}`;
    if (otherData.sendReference) {
        redirectUrl = redirectUrl + `?referenceId=${ReferenceId}&referenceType=${ReferenceType}`
        redirectUrl = Cheapest > 0 ? redirectUrl + `&cheapestProduct=${Cheapest}` : Discounted > 0 ? redirectUrl + `&discountedProduct=${Discounted}` : redirectUrl
    }
    if (otherData.reload) {
        window.location.href = redirectUrl
    } else {
        history.push(
            redirectUrl
        );
    }
}

function handleSwitchEntity(actionData, otherData) {
    const {
        TrackingMetadata
    } = actionData;
    if (otherData?.searchCall && (typeof otherData?.searchCall === "function")) {
        otherData.searchCall(queryString.stringify(TrackingMetadata))
    }
}

function handleCatalogActionType(actionData, history, otherData) {
    const {
        EntityType,
        EntityId,
        SubEntityId,
        ReferenceType,
        ReferenceId,
        Endpoint,
        Title,
        TrackingMetadata
    } = actionData
    const url = Endpoint.split("?")
    let redirectUrl = `/buyer/entity/${EntityType}/${EntityId}/${SubEntityId}?`;
    if (url?.length > 1) {
        redirectUrl += `&${url[1]}`;
    }
    if (otherData?.query?.length) {
        redirectUrl += `&query=${otherData?.query}`;
    }
    if (Title) {
        redirectUrl += `&title=${encodeURIComponent(Title)}`;
    }
    if (typeof TrackingMetadata === "object") {
        redirectUrl += "&" + queryString.stringify(TrackingMetadata)
    }

    window.location.href = redirectUrl

}


function handleReferralActionType(history, otherData) {
    if (otherData.reload) {
        window.location.href = "/referral"
    } else {
        history.push(`/referral`);
    }
}

function handleCartActionType(history, otherData) {
    if (otherData.reload) {
        window.location.href = "/buyer/cart"
    } else {
        history.push(`/buyer/cart`);
    }
}

function handleHyperpureCreditsActionType(history, otherData, trackingParams, trackingParamsCall) {
    if (trackingParams && typeof trackingParamsCall == "function") {
        trackingParamsCall(trackingParams)
    }
    window.location.href = `/buyer/credit`;
}

function handleCreditNoteActionType(actionData, history, otherData) {
    if (otherData.reload) {
        window.location.href = `/z/buyer/order/${(actionData || {}).OrderNumber
            }?type=number&open=credit-notes&credit-note-no=${(actionData || {}).CreditNoteNumber
            }`
    } else {
        history.push(
            `/z/buyer/order/${(actionData || {}).OrderNumber
            }?type=number&open=credit-notes&credit-note-no=${(actionData || {}).CreditNoteNumber
            }`
        );
    }
}

function handleCreditLimitActionType(history, otherData, trackingParams, trackParamsCall) {
    if (trackingParams && typeof trackParamsCall == "function") {
        trackParamsCall(trackingParams)
    }

    window.location.href = "/buyer/credit-limit"

    if (otherData.trackPopUp) {
        otherData.trackPopUp();
    }
}

function handleOrderHistoryActionType(history, otherData) {

    window.location.href = "/z/buyer/order"

}

function handleOrderActionType(actionData, history, otherData) {
    if (otherData.reload) {
        window.location.href = `/buyer/order/${(actionData || {}).OrderNumber}/number`;
    } else {
        history.push(`/buyer/order/${(actionData || {}).OrderNumber}/number`);
    }
}

function handleProductActionType(actionData, history, otherData = {}) {
    if (otherData.id !== undefined && otherData.commercialName !== undefined) {
        if (actionData.length !== 0) {
            let redirectUrl = `/buyer/banner?id=${otherData.id}&commercialName=${otherData.commercialName}`
            if (otherData.sendReference) {
                redirectUrl = redirectUrl + `?referenceId=${actionData.ReferenceId}&referenceType=${actionData.ReferenceType}`
            }
            if (otherData.reload) {
                window.location.href = redirectUrl;
            } else {
                history.push(redirectUrl);
            }
        }
    }
}

function handlePayLaterActionType(history, otherData) {
    window.location.href = `/paylater`;
    if (otherData.trackPopUp) {
        otherData.trackPopUp();
    }
}

function handleRewardsActionType(history) {
    history.push('/rewards');
}

function handleProfileActionType(history) {
    window.location.href='/in/profile'
}

function handleHelpActionType(history) {
    window.location.href='/in/contact';
}

function handleAccountStatement(otherData) {
    otherData.toggleSOAModal()
}

function toggleScroll(hideScroll = false) {
    const bodyTag = document.getElementsByTagName("BODY")[0]
    if (hideScroll) {
        bodyTag.style.overflowY = "hidden"
        bodyTag.maxHeight = "100dvh";
    } else {
        bodyTag.style.overflowY = "scroll"
        bodyTag.maxHeight = "100%";
    }
}

function getESV2FlagFromQueryParam(param) {
    const searchParam = queryString.parse(window.location.search);
    if (searchParam[param] || searchParam[EsV2QueryParams.ES_V2_FLAG]) {
        if (searchParam[param] === "true" || searchParam[EsV2QueryParams.ES_V2_FLAG] === "true") {
            window.localStorage.setItem(param, "true");
            return true;
        } else {
            window.localStorage.setItem(param, "false");
            return false;
        }
    } else {
        const localStorageVal = window.localStorage.getItem(param)
        return !!(localStorageVal && localStorageVal === "true");
    }
}


function getOrderDate(date, format) {
    let momentObj = moment(date).format(format).split(" ");
    let test = momentObj.map((item, index) => {
        if (index !== (momentObj.length - 1)) {
            return item.length ? item[0].toUpperCase() + item.slice(1, item.length).toLowerCase() : ""
        } else {
            return item
        }
    })
    return test.join(" ");
}

function handleDismissNudgeCTA(trackingParams, trackParamsCall) {
    if (trackingParams && typeof trackParamsCall == "function") {
        trackParamsCall(trackingParams)
    }
}

function newLineText(text) {
    return text.split('\n').map(str => <div>{str}</div>);
}

function toPascalCase(s) {
    return s.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
}

function customDownloadLink(url, fileName, fileExt) {
    let fileNode = document.createElement("a");
    fileNode.href = url;
    fileNode.download = `${fileName}.${fileExt}`;
    fileNode.setAttribute('download', `${fileName}.${fileExt}`);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    if (typeof fileNode.download === "undefined") {
        fileNode.target = "_blank";
    }

    // add to DOM and click
    document.body.appendChild(fileNode);
    fileNode.click();
    // clean up
    fileNode.remove();
    window.URL.revokeObjectURL(url);
}

function htmlSanitizedString(htmlString) {
    return htmlString.replace(/<[^>]+>/g, '')
}

function getImageMeta(url, cb) {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
};

function getValidatedPasswordArray(pass) {
    let validatedArray = []
    for (let re of PASSWORD_REGEX) {
        let passed = false
        if (re.regex.test(pass)) {
            passed = true
        }
        validatedArray.push({
            ...re,
            isValid: passed
        })
    }
    return validatedArray
}


function clearLocalStorage() {
    localStorage.removeItem("Payload_Token");
    localStorage.removeItem("Access_Token");
    localStorage.removeItem("accessTokenExpiration");
    localStorage.removeItem("renderChatBoxEverywhere");
}

function DecodeUrlSearchParams(source) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sourceParam = urlSearchParams.get(source);
    return sourceParam;
};

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=None; Partitioned; Secure";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Partitioned; Secure';
}

function getBase64(name) {
    const nameString = JSON.stringify(name);
    const encoded = btoa(nameString);
    return encoded;
}

function sendGTAGEvent(eventType, obj) {
    if (window.gtag) {
        window.gtag("event", eventType, obj)
    }
}


function getCookieForEntity(docCookieName, iframeCookieName) {
    if ((iframeCookieName === "clientType" || iframeCookieName === "clientData")) {
        if (isIframe()) {
            return getCookie(iframeCookieName)
        }
    } else {
        if (docCookieName) {
            return getCookie(docCookieName)
        } else if (iframeCookieName) {
            return getCookie(iframeCookieName)
        }
        return ""
    }
}


function isIframe() {
    return typeof window !== "undefined" && (window !== window.parent)
}

function setCookieForEntity(docCookieName, iframeCookieName, value, days) {
    if (docCookieName) {
        return setCookie(docCookieName, value, days)
    } else if (iframeCookieName) {
        return setCookie(iframeCookieName, value, days)
    }
}

function deleteCookieForEntity(docCookieName, iframeCookieName) {
    if (docCookieName) {
        return eraseCookie(docCookieName)
    } else if (iframeCookieName) {
        return eraseCookie(iframeCookieName)
    }
}


const getOutletIdFromBrowser = () => {
    let outletId = getCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID);
    if (window.localStorage.getItem(COOKIE_KEYS.OUTLET_ID)) {
        let outletIdLocal = window.localStorage.getItem(COOKIE_KEYS.OUTLET_ID);
        setCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID, outletIdLocal, COOKIE_TTL_FOR_WEB)
        outletId = outletIdLocal
        window.localStorage.removeItem(COOKIE_KEYS.OUTLET_ID)
    }
    if (getCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID)) {
        outletId = getCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID)
    }
    return outletId
};


const getApiVersionForWeb = () => {
    var url = new URL(window.location.href);
    let apiVersion = url.searchParams.get(COOKIE_KEYS.API_VERSION);
    if (readCookie(COOKIE_KEYS.API_VERSION)) {
        apiVersion = readCookie(COOKIE_KEYS.API_VERSION);
    }
    return apiVersion ? apiVersion : WEB_GLOBAL_API_VERSION;
};


const getAppTypeForWeb = () => {
    if (sessionStorage.getItem("onboarding_source") === APP_TYPE.PARTNER_WEB) {
        return APP_TYPE.PARTNER_WEB;
    } else if (typeof window !== "undefined" && getCookieForEntity("", "clientType") === CLIENT_TYPE_PETPOOJA) {
        return APP_TYPE.POS_PARTNER_WEB;
    } else {
        return window.outerWidth < MOBILE_WEB_SCREEN_SIZE ? APP_TYPE.MOBILE_WEB : APP_TYPE.WEB;
    }
};


function keyById(array, key) {
    if (array && array.length) {
        return array.reduce((acc, obj) => {
            acc[obj[key]] = obj;
            return acc;
        }, {});
    }
    return [];
}


function keyByIdWithArray(array, key) {
    if (array && array.length) {
        return array.reduce((acc, obj) => {
            if (!acc[obj[key]]) {
                acc[obj[key]] = [];
            }
            acc[obj[key]].push(obj);
            return acc;
        }, {});
    }
    return [];
}


function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    }
    return str;
}


function handleCallBack(callBack, callBackParams = null) {
    if (typeof callBack === "function") {
        if (callBackParams) {
            callBack({ ...callBackParams });
        } else {
            callBack();
        }
    }
}


function getAuthHeader(isPowerRanger) {
    return `${isPowerRanger ? "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MzQ4LCJDbGllbnRUeXBlIjoidXNlciIsIlRlbmFudElkIjoxLCJXYXJlaG91c2VDb2RlIjoiV0gtREVMMSIsImV4cCI6MTY2Nzc2NTQ4Mn0.eUQQng8nQ0GnJC7TugLcBLCFIFZdQ6peJCjfPihoDnI" : getCookie("token")}`;
}


function readCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}


function getAppMode() {
    let appMode = getCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE);
    if (appMode === undefined || appMode === "" || getAppTypeForWeb() === APP_TYPE.MOBILE_WEB) {
        appMode = APP_MODE.STANDARD;
    }
    return appMode;
}


function getCheckoutMode(appMode, isCombinedCheckout) {
    if (isCombinedCheckout) {
        return CHECKOUT_MODE.COMBINED;
    }
    switch (appMode) {
        case APP_MODE.EXPRESS:
            return CHECKOUT_MODE.EXPRESS;
        case APP_MODE.STANDARD:
            return CHECKOUT_MODE.STANDARD;
        default:
            return CHECKOUT_MODE.STANDARD;
    }
}


function getCheckoutParams(checkoutMode, paramsData) {
    switch (checkoutMode) {
        case CHECKOUT_MODE.EXPRESS:
            return paramsData.EXPRESS;
        case CHECKOUT_MODE.STANDARD:
            return paramsData.STANDARD;
        case CHECKOUT_MODE.COMBINED:
            return paramsData.COMBINED;
        default:
            return paramsData.STANDARD;
    }
}

function isPetPoojaClient() {
    return getCookieForEntity("", "clientType") === CLIENT_TYPE_PETPOOJA
}

function getBrowserStorageType(isSession) {
    return isSession ? sessionStorage : localStorage;
}

// Utility to safely set localStorage with ttl 1 day as default
function SetLocalStorageForEntity(key, value, isSession = false) {
    try {
        const serializedValue = JSON.stringify(value); // Convert to JSON string
        getBrowserStorageType(isSession).setItem(key, serializedValue);
    } catch (e) {
        alert("Something went wrong");
    }
}

// Utility to safely get localStorage
function GetLocalStorageForEntity(key, isSession = false) {
    try {
        const value = getBrowserStorageType(isSession).getItem(key);
        return value ? JSON.parse(value) : null; // Parse JSON back to object
    } catch (e) {
        return null;
    }
}

// Utility to safely remove an item from localStorage
function RemoveLocalStorageForEntity(key, isSession = false) {
    try {
        getBrowserStorageType(isSession).removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

function GetPetPoojaClientConfiguration() {
    const cookieData = parseJson(getCookieForEntity("", "clientData"));
    const localStoreData = GetLocalStorageForEntity(LOCAL_STORE_KEYS.POS_STORES_DATA, true);
    return {
        ...cookieData,
        all_store_obj: localStoreData,
    };
}

function getHpPlusData(cartData) {

    let appMode = getCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE);
    if (appMode === APP_MODE_STANDARD) {
        const hpPlusPlanCardData = cartData.find(
            (item) => item.SectionType === HP_PLUS_PLAN_CARD
        );
        return hpPlusPlanCardData || {};
    }
}

export {
    loadingHelper,
    debounce,
    getFormattedINRValues,
    getCache,
    getScroll,
    usePrevious,
    useOnUpdate,
    getThousandsFormatCurrency,
    makeAndSaveBlob,
    saveLink,
    removeExtraPrecision,
    parseIntOr0,
    isMobile,
    handleActionType,
    getESV2FlagFromQueryParam,
    toggleScroll,
    getOrderDate,
    isReduxBasedAction,
    newLineText,
    toPascalCase,
    isRequired,
    customDownloadLink,
    htmlSanitizedString,
    getImageMeta,
    getValidatedPasswordArray,
    clearLocalStorage,
    DecodeUrlSearchParams,
    setCookie,
    getCookie,
    eraseCookie,
    getBase64,
    getCookieForEntity,
    isIframe,
    setCookieForEntity,
    deleteCookieForEntity,
    sendGTAGEvent,
    isTabSelected,
    keyById,
    keyByIdWithArray,
    truncateString,
    handleCallBack,
    getAuthHeader,
    readCookie,
    getOutletIdFromBrowser,
    getApiVersionForWeb,
    getAppTypeForWeb,
    getCheckoutMode,
    getCheckoutParams,
    getAppMode,
    parseJson,
    isPetPoojaClient,
    SetLocalStorageForEntity,
    GetLocalStorageForEntity,
    RemoveLocalStorageForEntity,
    GetPetPoojaClientConfiguration,
    getHpPlusData
};